<template>
  <div id="app">
    <exhibitionEntry></exhibitionEntry>
  </div>
</template>

<script>
import ExhibitionEntry from "@/views/ExhibitionEntry.vue";

export default {
  components: {
    exhibitionEntry: ExhibitionEntry
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
