import Vue from "vue";
import App from "./App.vue";

import api from "./api.js";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "./assets/scss/app.scss";

import "./validator.js";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";
setInteractionMode("eager");
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(api);
Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount("#app");
