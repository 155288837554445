import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} je povinné pole."
});

extend("requiredAttachment", {
  ...required,
  message: "{_field_} je povinná příloha."
});

extend("email", {
  ...email,
  message: "E-mailová adresa není platná."
});

extend("postalCode", {
  validate: value => {
    return value
      .toString()
      .trim()
      .match(/^([1-9][0-9]{2}[ ]{0,1}[0-9]{2})$/);
  },
  message: "PSČ je neplatné."
});

extend("phone", {
  validate: value => {
    return value
      .toString()
      .trim()
      .match(
        /^([+][1-9][0-9]{2}[ ]{0,1}[0-9]{3}[ ]{0,1}[0-9]{3}[ ]{0,1}[0-9]{3})$/
      );
  },
  message: "Telefonní číslo je neplatné."
});

extend("agreed", {
  validate: value => {
    return value;
  },
  message: "Prosíme, potvrďte."
});

extend("fileSize", {
  validate: value => {
    return value.size <= 2000000;
  },
  message: "Velikost souboru je větší než 2 MB."
});
