import axios from "axios";

const host = window.location.host;

const apiClient = axios.create({
  baseURL: host.startsWith("localhost")
    ? "https://localhost/kchrr/api"
    : "https://kchrr.ux2go.eu/api",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const Api = {};

Api.install = function(Vue) {
  Vue.prototype.$submitExhibitionEntry = async function(payload) {
    const response = await apiClient.post(
      "/application/?action=submit-entry",
      payload,
      {
        headers: {
          "authorized-entry": "true",
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return response;
  };
};

export default Api;
