<template>
  <div class="exhibition-entry">
    <b-loading :is-full-page="true" v-model="isSubmitting" :can-cancel="false">
    </b-loading>
    <section class="hero is-primary has-bg-image">
      <div class="hero-body">
        <div class="content">
          <div class="level">
            <div class="level-left">
              <div>
                <p class="title">Klub chovatelů rhodéských ridgebacků</p>
                <p class="subtitle">On-line přihláška</p>
              </div>
            </div>
            <div class="level-right">
              <a @click="newApplication()">
                <b-icon icon="dots-horizontal" type="is-dark"> </b-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section" v-if="!appFinished">
      <div class="columns">
        <div class="column"></div>
        <div class="column is-half">
          <div class="content">
            <b-message
              type="is-primary"
              v-if="!showClosingSoonWarning && !isClosed"
            >
              <div v-for="aDate in config.partialClosings" :key="aDate.seq">
                {{ aDate.seq }}. uzávěrka: {{ formatDate(aDate.cDate) }}.
              </div>
            </b-message>
            <b-message type="is-warning" v-if="showClosingSoonWarning">
              Přihlašování na výstavu bude ukončeno k
              {{ formatDateTime(config.closingDate) }}.
            </b-message>
            <b-message type="is-danger" v-if="isClosed">
              Přihlašování na výstavu bylo ukončeno k
              {{ formatDateTime(config.closingDate) }}.
            </b-message>
            <!-- pouze pro dvoudenní výstavu -->

            <ValidationObserver ref="entryForm" v-if="!isClosed">
              <form>
                <h3>Klubová a Speciální výstava KCHRR 28. - 29. května 2022</h3>

                <!-- pouze pro dvoudenní výstavu -->
                <ValidationProvider
                  rules="required"
                  name="Účast na výstavě"
                  vid="exhibitionAttendance"
                  v-slot="{ errors }"
                  slim
                  ref="exhibitionAttendanceValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Účast na výstavě"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-select
                      placeholder="Vyberte..."
                      icon="calendar-clock"
                      v-model="entry.exhibitionAttendance"
                    >
                      <option
                        v-for="attendance in attendanceOptions"
                        :value="attendance.value"
                        :key="attendance.value"
                      >
                        {{ attendance.desc }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
                <!-- pouze pro dvoudenní výstavu -->

                <ValidationProvider
                  rules="required"
                  name="Členství v klubu KCHRR"
                  vid="kchrrMember"
                  v-slot="{ errors }"
                  slim
                  ref="kchrrMemberValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Členství v klubu KCHRR"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-select
                      placeholder="Vyberte..."
                      icon="account-star"
                      v-model="entry.kchrrMember"
                    >
                      <option
                        v-for="membership in membershipOptions"
                        :value="membership.value"
                        :key="membership.value"
                      >
                        {{ membership.desc }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <h3>Informace o psu</h3>

                <ValidationProvider
                  rules="required"
                  name="Pohlaví"
                  vid="sex"
                  v-slot="{ errors }"
                  slim
                  ref="sexValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Pohlaví"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-select
                      placeholder="Vyberte..."
                      icon="gender-male-female"
                      v-model="entry.sex"
                    >
                      <option
                        v-for="dogSex in sexOptions"
                        :value="dogSex.value"
                        :key="dogSex.value"
                      >
                        {{ dogSex.desc }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Třída"
                  vid="class"
                  v-slot="{ errors }"
                  slim
                  ref="classValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Třída"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-select
                      placeholder="Vyberte..."
                      icon="star-circle-outline"
                      v-model="entry.classKind"
                    >
                      <option
                        v-for="classKind in classOptions"
                        :value="classKind.value"
                        :key="classKind.value"
                      >
                        {{ classKind.desc }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Jméno"
                  vid="dogName"
                  v-slot="{ errors }"
                  slim
                  ref="dogNameValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Jméno psa"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-input icon="dog" v-model="entry.name"> </b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Číslo zápisu"
                  vid="breedBookRecordNumber"
                  v-slot="{ errors }"
                  slim
                  ref="breedBookRecordNumberValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Číslo zápisu"
                    :type="{ 'is-danger': errors[0] }"
                    :message="
                      errors[0]
                        ? errors[0]
                        : 'Uvádějte včetně zkratky plemenné knihy.'
                    "
                  >
                    <b-input
                      icon="book-open-variant"
                      v-model="entry.breedBookRecordNumber"
                    >
                    </b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Datum narození"
                  vid="birthDateObject"
                  v-slot="{ errors }"
                  slim
                >
                  <b-field
                    horizontal
                    label="Datum narození"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-datepicker
                      v-model="birthDateObject"
                      :month-names="monthNames"
                      :day-names="dayNames"
                      :date-formatter="formatDate"
                      placeholder="DD.MM.RRRR"
                      icon="calendar"
                      trap-focus
                      :first-day-of-week="1"
                      locale="de-DE"
                      editable
                    >
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Jméno otce"
                  vid="father"
                  v-slot="{ errors }"
                  slim
                  ref="fatherValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Otec"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-input icon="gender-male" v-model="entry.father">
                    </b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Jméno matky"
                  vid="mother"
                  v-slot="{ errors }"
                  slim
                  ref="motherValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Matka"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-input icon="gender-female" v-model="entry.mother">
                    </b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Jméno chovatele"
                  vid="breeder"
                  v-slot="{ errors }"
                  slim
                  ref="breederValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Chovatel"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-input icon="account" v-model="entry.breeder"> </b-input>
                  </b-field>
                </ValidationProvider>

                <h3>Kontaktní údaje majitele</h3>

                <ValidationProvider
                  rules="required"
                  name="Jméno majitele"
                  vid="owner"
                  v-slot="{ errors }"
                  slim
                  ref="ownerValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Celé jméno"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-input icon="account" v-model="entry.owner"> </b-input>
                  </b-field>
                </ValidationProvider>

                <b-field horizontal grouped label="Adresa">
                  <ValidationProvider
                    rules="required"
                    name="Ulice"
                    vid="street"
                    v-slot="{ errors }"
                    slim
                    ref="streetValidationProvider"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0] }"
                      :message="errors[0]"
                    >
                      <b-input
                        icon="home"
                        v-model="entry.street"
                        placeholder="Ulice"
                      >
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required"
                    name="Číslo"
                    vid="streetNumber"
                    v-slot="{ errors }"
                    slim
                    ref="streetNumberValidationProvider"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0] }"
                      :message="errors[0]"
                    >
                      <b-input v-model="entry.streetNumber" placeholder="Číslo">
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                </b-field>

                <b-field horizontal grouped label="">
                  <ValidationProvider
                    rules="required"
                    name="Místo"
                    vid="city"
                    v-slot="{ errors }"
                    slim
                    ref="cityValidationProvider"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0] }"
                      :message="errors[0]"
                    >
                      <b-input
                        icon="home-city"
                        v-model="entry.city"
                        placeholder="Místo"
                      >
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required|postalCode:5"
                    name="PSČ"
                    vid="postalCode"
                    v-slot="{ errors }"
                    slim
                    ref="postalCodeValidationProvider"
                  >
                    <b-field
                      :type="{ 'is-danger': errors[0] }"
                      :message="errors[0]"
                    >
                      <b-input v-model="entry.postalCode" placeholder="PSČ">
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                </b-field>

                <ValidationProvider
                  rules="required|phone"
                  name="Telefonní číslo"
                  vid="phone"
                  v-slot="{ errors }"
                  slim
                  ref="phoneValidationProvider"
                >
                  <b-field
                    horizontal
                    label="Telefon"
                    :type="{ 'is-danger': errors[0] }"
                    :message="
                      errors[0]
                        ? errors[0]
                        : 'Zadejte číslo ve formátu +420 123 567 890'
                    "
                  >
                    <b-input icon="phone" v-model="entry.phone"> </b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required|email"
                  name="E-mail"
                  vid="email"
                  v-slot="{ errors }"
                  slim
                  ref="emailValidationProvider"
                >
                  <b-field
                    horizontal
                    label="E-mail"
                    :type="{ 'is-danger': errors[0] }"
                    :message="
                      errors[0]
                        ? errors[0]
                        : 'Na uvedený e-mail obdržíte před konáním výstavy vstupní list. Je třeba jej vytisknout a přinést s sebou na výstavu (viz propozice).'
                    "
                  >
                    <b-input icon="email-outline" v-model="entry.email">
                    </b-input>
                  </b-field>
                </ValidationProvider>

                <h3>Prohlášení účastníka výstavy</h3>

                <ValidationProvider
                  rules="agreed"
                  name="Agreement1"
                  vid="agreement1"
                  v-slot="{ errors }"
                  slim
                  ref="agreement1ValidationProvider"
                >
                  <b-field
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-switch v-model="entry.agreement1">
                      <span :class="{ 'has-text-danger': errors[0] }"
                        >Prohlašuji, že jsou mi známá ustanovení propozic a že
                        se jim podrobuji.</span
                      >
                    </b-switch>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider
                  rules="agreed"
                  name="Agreement2"
                  vid="agreement2"
                  v-slot="{ errors }"
                  slim
                  ref="agreement2ValidationProvider"
                >
                  <b-field
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors[0]"
                  >
                    <b-switch v-model="entry.agreement2">
                      <span :class="{ 'has-text-danger': errors[0] }"
                        >Souhlasím s uveřejněním své adresy v katalogu
                        výstavy.</span
                      >
                    </b-switch>
                  </b-field>
                </ValidationProvider>

                <h3>Povinné dokumenty</h3>

                <p>
                  Všechny požadované dokumenty je třeba doložit pomocí tohoto
                  formuláře. Pokud nemáte možnost dokumenty naskenovat, postačí
                  i čitelná fotografie. Pokud byste neměli možnost dokumenty ani
                  naskenovat, ani vyfotit, prosíme, zašlete přihlášku i s
                  dokumenty v papírové podobě poštou na adresu organizátorů,
                  kterou naleznete v Propozicích.
                </p>
                <p>
                  Soubory zaslané přes tento formulář mohou být pouze typu JPG
                  nebo PDF. Maximální velikost je 2 MB.
                </p>

                <ValidationProvider
                  rules="requiredAttachment|fileSize"
                  name="Průkaz původu"
                  vid="filePedigree1"
                  v-slot="{ errors }"
                  slim
                  ref="filePedigree1ValidationProvider"
                >
                  <div class="mb-3">
                    <b-field
                      class="file"
                      :class="{
                        'is-primary': errors.length == 0,
                        'is-danger': errors[0],
                        'has-name': !!entryAttachments.filePedigree1,
                      }"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-upload
                        native
                        accept="application/pdf, image/jpeg"
                        v-model="entryAttachments.filePedigree1"
                        class="file-label"
                        @input="
                          validateFile(
                            entryAttachments.filePedigree1,
                            'filePedigree1ValidationProvider'
                          )
                        "
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Průkaz původu, str. 1</span>
                        </span>
                        <span
                          class="file-name"
                          v-if="entryAttachments.filePedigree1"
                        >
                          {{ entryAttachments.filePedigree1.name }}
                        </span>
                      </b-upload>
                    </b-field>
                    <p
                      class="help is-danger"
                      style="margin-top: -0.5rem"
                      v-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  rules="fileSize"
                  name="Průkaz původu"
                  vid="filePedigree2"
                  v-slot="{ errors }"
                  slim
                  ref="filePedigree2ValidationProvider"
                >
                  <div class="mb-3">
                    <b-field
                      class="file"
                      :class="{
                        'is-primary': errors.length == 0,
                        'is-danger': errors[0],
                        'has-name': !!entryAttachments.filePedigree2,
                      }"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-upload
                        native
                        accept="application/pdf, image/jpeg"
                        v-model="entryAttachments.filePedigree2"
                        class="file-label"
                        @input="
                          validateFile(
                            entryAttachments.filePedigree2,
                            'filePedigree2ValidationProvider'
                          )
                        "
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Průkaz původu, str. 2</span>
                        </span>
                        <span
                          class="file-name"
                          v-if="entryAttachments.filePedigree2"
                        >
                          {{ entryAttachments.filePedigree2.name }}
                        </span>
                      </b-upload>
                    </b-field>
                    <p
                      class="help is-danger"
                      style="margin-top: -0.5rem"
                      v-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  v-if="entry.classKind == 8 || entry.classKind == 9"
                  rules="requiredAttachment|fileSize"
                  name="Šampionát"
                  vid="fileChampionship"
                  v-slot="{ errors }"
                  slim
                  ref="fileChampionshipValidationProvider"
                >
                  <div class="mb-3">
                    <b-field
                      class="file"
                      :class="{
                        'is-primary': errors.length == 0,
                        'is-danger': errors[0],
                        'has-name': !!entryAttachments.fileChampionship,
                      }"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-upload
                        native
                        accept="application/pdf, image/jpeg"
                        v-model="entryAttachments.fileChampionship"
                        class="file-label"
                        @input="
                          validateFile(
                            entryAttachments.fileChampionship,
                            'fileChampionshipValidationProvider'
                          )
                        "
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Šampionát</span>
                        </span>
                        <span
                          class="file-name"
                          v-if="entryAttachments.fileChampionship"
                        >
                          {{ entryAttachments.fileChampionship.name }}
                        </span>
                      </b-upload>
                    </b-field>

                    <p
                      class="help is-danger"
                      style="margin-top: -0.5rem"
                      v-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </p>
                    <p
                      class="help"
                      style="margin-top: -0.5rem"
                      v-if="entry.classKind == 9"
                    >
                      Doložte případně kopie titulu Klubový vítěz, Vítěz
                      speciální výstavy, Národní vítěz a šampionát členského
                      státu FCI.<br />Tituly musí být získány v třídách
                      dospělých.
                    </p>
                    <p
                      class="help"
                      style="margin-top: -0.5rem"
                      v-if="entry.classKind == 8"
                    >
                      Přiložte kopii titulu ICH, CH, NV, KV nebo Vítěz speciální
                      výstavy.
                    </p>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  v-if="entry.classKind == 7"
                  rules="requiredAttachment|fileSize"
                  name="Certifikát o zkouškách"
                  vid="fileExamCertificate"
                  v-slot="{ errors }"
                  slim
                  ref="fileExamCertificateValidationProvider"
                >
                  <div class="mb-3">
                    <b-field
                      class="file"
                      :class="{
                        'is-primary': errors.length == 0,
                        'is-danger': errors[0],
                        'has-name': !!entryAttachments.fileExamCertificate,
                      }"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-upload
                        native
                        accept="application/pdf, image/jpeg"
                        v-model="entryAttachments.fileExamCertificate"
                        class="file-label"
                        @input="
                          validateFile(
                            entryAttachments.fileExamCertificate,
                            'fileExamCertificateValidationProvider'
                          )
                        "
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label">Doklad o zkouškách</span>
                        </span>
                        <span
                          class="file-name"
                          v-if="entryAttachments.fileExamCertificate"
                        >
                          {{ entryAttachments.fileExamCertificate.name }}
                        </span>
                      </b-upload>
                    </b-field>
                    <p
                      class="help is-danger"
                      style="margin-top: -0.5rem"
                      v-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  rules="requiredAttachment|fileSize"
                  name="Doklad o uhrazení poplatku"
                  vid="filePaymentConfirmation"
                  v-slot="{ errors }"
                  slim
                  ref="filePaymentConfirmationValidationProvider"
                >
                  <div class="mb-3">
                    <b-field
                      class="file"
                      :class="{
                        'is-primary': errors.length == 0,
                        'is-danger': errors[0],
                        'has-name': !!entryAttachments.filePaymentConfirmation,
                      }"
                      :type="{ 'is-danger': errors[0] }"
                    >
                      <b-upload
                        native
                        accept="application/pdf, image/jpeg"
                        v-model="entryAttachments.filePaymentConfirmation"
                        class="file-label"
                        @input="
                          validateFile(
                            entryAttachments.filePaymentConfirmation,
                            'filePaymentConfirmationValidationProvider'
                          )
                        "
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"></b-icon>
                          <span class="file-label"
                            >Doklad o uhrazení poplatku</span
                          >
                        </span>
                        <span
                          class="file-name"
                          v-if="entryAttachments.filePaymentConfirmation"
                        >
                          {{ entryAttachments.filePaymentConfirmation.name }}
                        </span>
                      </b-upload>
                    </b-field>
                    <p
                      class="help is-danger"
                      style="margin-top: -0.5rem"
                      v-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>

                <h3>Vzkaz organizátorům</h3>
                <b-field>
                  <b-input
                    type="textarea"
                    placeholder="Chcete-li nám sdělit cokoliv dalšího, napište Váš vzkaz sem."
                    rows="4"
                    custom-class="has-fixed-size"
                    v-model="entry.notes"
                  ></b-input>
                </b-field>
                <b-field>
                  <b-button
                    type="is-primary"
                    size="is-large"
                    @click="validateAndSubmit()"
                  >
                    <b-icon icon="send"></b-icon>
                    <span>Odeslat přihlášku</span>
                  </b-button>
                </b-field>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div class="column"></div>
        <!-- <div class="column">
          <div class="content">
            <b-image
              :src="require('@/assets/poster.jpg')"
              alt="Plakát výstavy"
            ></b-image> 
          </div>
        </div> -->
      </div>
    </section>

    <section class="section" v-if="appFinished">
      <h2 class="title">Děkujeme, hezký den!</h2>
      <p>
        Budete-li chtít dodatečně přihlásit dalšího psa, odkaz na on-line
        přihlášku naleznete na <a href="https://www.kchrr.com">webu KCHRR</a>.
      </p>
    </section>

    <b-modal
      v-model="showEntryConfirmation"
      has-modal-card
      :can-cancel="['escape', 'x']"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Přihláška úspěšně podána</p>
        </header>
        <section class="modal-card-body">
          <b-message size="is-small" type="is-success">
            Vaše přihláška byla úspěšně podána. Evidujeme ji pod číslem
            {{ lastEntryID }}.
          </b-message>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="showEntryConfirmation = false"> Konec </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ExhibitionEntry",
  data() {
    return {
      entryTest: {
        exhibitionAttendance: 1,
        kchrrMember: 1,
        sex: "pes",
        classKind: 3,
        name: "LOJZA",
        breedBookRecordNumber: "Záznam č. 1 / ZV1",
        birthDate: this.formatDate(new Date()),
        father: "Lojzův Otec",
        mother: "Lojzova Matka",
        breeder: "Lenička",
        owner: "Lenka Filipová",
        street: "Hrabákova",
        streetNumber: "1973/3",
        city: "Praha 4 - Chodov",
        postalCode: "148 00",
        phone: "+420 607 818 419",
        email: "lojza.filip@seznam.cz",
        agreement1: true,
        agreement2: true,
        notes: "Těším se! :)",
      },
      entry: {
        exhibitionAttendance: null,
        kchrrMember: null,
        sex: null,
        classKind: null,
        name: null,
        breedBookRecordNumber: null,
        birthDate: null,
        father: null,
        mother: null,
        breeder: null,
        owner: null,
        street: null,
        streetNumber: null,
        city: null,
        postalCode: null,
        phone: "+420 ",
        email: null,
        agreement1: false,
        agreement2: false,
        notes: null,
      },
      entryAttachments: {
        filePedigree1: null,
        filePedigree2: null,
        fileChampionship: null,
        fileExamCertificate: null,
        filePaymentConfirmation: null,
      },
      birthDateObject: null,
      monthNames: [
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec",
      ],
      dayNames: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
      attendanceOptions: [
        { desc: "Pouze sobota 28. května 2022", value: 1 },
        { desc: "Pouze neděle 29. května 2022", value: 2 },
        { desc: "Oba dny", value: 3 },
      ],
      membershipOptions: [
        { desc: "Majitel JE členem klubu KCHRR", value: 1 },
        { desc: "Majitel NENÍ členem klubu KCHRR", value: 2 },
      ],
      sexOptions: [
        { desc: "pes", value: "pes" },
        { desc: "fena", value: "fena" },
      ],
      classOptions: [
        { desc: "tiny baby (2 - 4 měsíce)", value: "1" },
        { desc: "štěňata (4 - 6 měsíců)", value: "2" },
        { desc: "dorost (6 - 9 měsíců)", value: "3" },
        { desc: "mladí (9 - 18 měsíců)", value: "4" },
        { desc: "mezitřída (15 - 24 měsíců)", value: "5" },
        { desc: "otevřená (od 15 měsíců)", value: "6" },
        { desc: "pracovní (od 15 měsíců)", value: "7" },
        { desc: "čestná, mimo konkurenci (od 15 měsíců)", value: "8" },
        { desc: "vítězové (od 15 měsíců)", value: "9" },
        { desc: "veteráni (od 8 let)", value: "10" },
      ],
      lastEntryID: "",
      showEntryConfirmation: false,
      appFinished: false,
      isSubmitting: false,
      config: {
        closingSoonWarning: new Date("2022-05-01T00:00:00+02:00"),
        closingDate: new Date("2022-05-13T23:59:59+02:00"),
        partialClosings: [
          { seq: 1, cDate: new Date("2022-04-22T00:00:00+02:00") },
          { seq: 2, cDate: new Date("2022-05-13T23:59:59+02:00") },
        ],
      },
    };
  },
  created() {},
  methods: {
    formatDate(date) {
      return (
        "" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
        "." +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "." +
        date.getFullYear()
      );
    },
    formatDateTime(date) {
      return (
        "" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
        "." +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "." +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
    showNotification(text, type) {
      this.$buefy.toast.open({
        message: text,
        position: "is-bottom",
        type: type,
        duration: 5000,
      });
    },
    confirmApplicationSubmitted() {
      this.$buefy.dialog.confirm({
        title: "Přihláška úspěšně podána",
        message: `Vaše přihláška byla úspěšně podána. Evidujeme ji pod číslem:<br /><br /><strong>${this.lastEntryID}</strong><br /><br />Na váš e-mail jsme zaslali potvrzení.<br /><br />
        Nyní můžete pokračovat přihlášením dalšího psa, ale zkontrolujte všechna pole, která zůstanou vyplněná.`,
        cancelText: "Konec",
        confirmText: "Nová přihláška",
        hasIcon: true,
        type: "is-success",
        onConfirm: () => this.newApplication(),
        onCancel: () => (this.appFinished = true),
      });
    },
    newApplication() {
      this.entry.name = null;
      this.entry.sex = null;
      this.entry.classKind = null;
      this.entry.name = null;
      this.entry.breedBookRecordNumber = null;
      this.birthDateObject = null;
      this.entry.father = null;
      this.entry.mother = null;
      this.entry.breeder = null;
      this.entry.notes = null;
      this.entryAttachments.filePedigree1 = null;
      this.entryAttachments.filePedigree2 = null;
      this.entryAttachments.fileChampionship = null;
      this.entryAttachments.fileExamCertificate = null;
      this.entryAttachments.filePaymentConfirmation = null;
    },
    async validateFile(file, ref) {
      if (file && this.$refs[ref]) {
        const validationResult = await this.$refs[ref].validate(file);
        return validationResult.valid;
      } else return true;
    },
    async validateAndSubmit() {
      let validationResult = true;
      validationResult = await this.$refs.entryForm.validate();
      validationResult = validationResult
        ? await this.validateFile(
            this.entryAttachments.filePedigree1,
            "filePedigree1ValidationProvider"
          )
        : false;
      validationResult = validationResult
        ? await this.validateFile(
            this.entryAttachments.fileChampionship,
            "fileChampionshipValidationProvider"
          )
        : false;
      validationResult = validationResult
        ? await this.validateFile(
            this.entryAttachments.fileExamCertificate,
            "fileExamCertificateValidationProvider"
          )
        : false;
      validationResult = validationResult
        ? await this.validateFile(
            this.entryAttachments.filePaymentConfirmation,
            "filePaymentConfirmationValidationProvider"
          )
        : false;
      if (validationResult !== true) {
        this.showNotification(
          "Přihláška není správně vyplněná. Zkontrolujte vyznačená pole.",
          "is-danger"
        );
      } else {
        try {
          this.isSubmitting = true;
          let formData = new FormData();
          formData.append("filePedigree1", this.entryAttachments.filePedigree1);
          formData.append("filePedigree2", this.entryAttachments.filePedigree2);
          formData.append(
            "fileChampionship",
            this.entryAttachments.fileChampionship
          );
          formData.append(
            "fileExamCertificate",
            this.entryAttachments.fileExamCertificate
          );
          formData.append(
            "filePaymentConfirmation",
            this.entryAttachments.filePaymentConfirmation
          );
          formData.append("entry", JSON.stringify(this.entry));
          const responseEntry = await this.$submitExhibitionEntry(formData);
          this.isSubmitting = false;
          if (responseEntry.data.statusCode === 200) {
            this.lastEntryID = responseEntry.data.responseData.applicationID;
            this.confirmApplicationSubmitted();
          } else if (responseEntry.data.statusCode === 406) {
            this.showNotification(
              "Vypršel termín pro přihlášení na výstavu. Vaši přihlášku již není možné zaevidovat.",
              "is-danger"
            );
          } else {
            this.showNotification(
              "Při odesílání přihlášky došlo k chybě. Zkuste přihlášku odeslat ještě jednou. Pokud chyba přetrvá, kontaktujte nás.",
              "is-danger"
            );
          }
        } catch (error) {
          this.isSubmitting = false;
          if (error.response.status === 406) {
            this.showNotification(
              "Vypršel termín pro přihlášení na výstavu. Vaši přihlášku již není možné zaevidovat.",
              "is-danger"
            );
          } else {
            this.showNotification(
              "Při odesílání přihlášky došlo k chybě. Zkuste přihlášku odeslat ještě jednou. Pokud chyba přetrvá, kontaktujte nás.",
              "is-danger"
            );
          }
          console.log(error);
        }
      }
    },
  },
  computed: {
    showClosingSoonWarning() {
      const now = new Date();
      return (
        now >= this.config.closingSoonWarning && now < this.config.closingDate
      );
    },
    isClosed() {
      const now = new Date();
      return now >= this.config.closingDate;
    },
  },
  watch: {
    birthDateObject() {
      this.entry.birthDate = this.birthDateObject
        ? this.formatDate(this.birthDateObject)
        : "";
    },
  },
};
</script>
